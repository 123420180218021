import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import authSelectors from 'src/modules/auth/authSelectors';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import DownloadApp from 'src/modules/shared/modal/DownloadApp';
import UpgradeRequired from 'src/modules/shared/modal/UpgradeRequired';
import Header from 'src/view/layout/Header';
import Menu from 'src/view/layout/Menu';

interface GPTTConfig {
  uuid: string;
}

function Layout(props) {
  const match = useRouteMatch();
  const location = useLocation();
  const [showDownloadAppModal, setShowDownloadAppModal]= useState(false);
  const [isUpgradeModaVisible, setIsUpgradeModalVisible] = useState(false);

let showHeader  = false;
const pathArray = location.pathname.split("/")

if(pathArray.includes("21-day-focus")|| pathArray.includes("health-biometrics")|| pathArray.includes("pricing")){
  showHeader = true;
}
  

  const isFullPageLoading = useSelector(
    layoutSelectors.selectFullPageLoading,
  );

  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Set the script source
    script.src = 'https://app.gpt-trainer.com/widget-asset.min.js';

    // Set the script async attribute to true
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);
   
    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); 

  function waitForElement(querySelector){
    return new Promise((resolve, reject)=>{
      var timer = false;
      if(document.querySelectorAll(querySelector).length) return resolve();
      const observer = new MutationObserver(()=>{
        if(document.querySelectorAll(querySelector).length){
          observer.disconnect();
          if(timer !== false) clearTimeout(timer);
          return resolve();
        }
      });
      observer.observe(document.body, {
        childList: true, 
        subtree: true
      });
    });
  }
  
  waitForElement("#chatbot-widget-window-iframe").then(function(){
    if(document.getElementById("chatbot-widget-window-iframe")){
      document.getElementById("chatbot-widget-window-iframe").style.display = "";
    }
  }).catch(()=>{
      alert("element did not load in 3 seconds");
  });

  useEffect(() => {
    // Set the GPTTConfig object after the script has loaded
    (window as any).GPTTConfig = {
      uuid: '7c77dc858a8e496395fdd7b4b6d4956e',
    } as GPTTConfig;
  }, []);

  

  return (
    <div className=" h-screen bg-white overflow-hidden flex">
      <Menu isUpgradeModaVisible={isUpgradeModaVisible} setIsUpgradeModalVisible={setIsUpgradeModalVisible} url={match.url} showDownloadAppModal={showDownloadAppModal} setShowDownloadAppModal={setShowDownloadAppModal}/>

      <div className="flex-1 flex flex-col">
        {(!currentUser ||
          !currentUser.passwordChangeRequired) && (
          
          <Header />
        )}

       {/* {  <div style={{ height: 63 }}>&#160;</div>} */}
        {/* Do not remove the ID, it's used to scroll programmatically */}
        <main
          id="main"
          className="mb-28 sm:mb-0 flex-1 relative z-0 focus:outline-none overflow-y-auto "
          tabIndex={0}
          style={{backgroundColor:'#f6f6f6'}}
        >
          {props.children}
        </main>
      </div>

      <div
        className={`${
          isFullPageLoading ? 'full-page-loading' : ''
        }`}
      ></div>
        {
        showDownloadAppModal && <DownloadApp setShowDownloadAppModal={setShowDownloadAppModal}/>
      }
    {
      isUpgradeModaVisible && <UpgradeRequired setIsUpgradeModalVisible={setIsUpgradeModalVisible}  isFrequency={false}/>
    }
    </div>
  );
}

export default Layout;
