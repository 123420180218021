import {
  faAmazon,
  faAndroid,
  faApple
} from '@fortawesome/free-brands-svg-icons';
import {
  faAddressCard,
  faCommentDots,
  faEnvelope,
  faNewspaper,
  faQuestionCircle,
  
} from '@fortawesome/free-regular-svg-icons';
import {
  faCog,
  faHeart,
  faPlay,
  faTv,
} from '@fortawesome/free-solid-svg-icons';
import { i18n } from 'src/i18n';
import Permissions from 'src/security/permissions';
import getStore from 'src/modules/store';
import PermissionChecker from 'src/modules/auth/permissionChecker';

const permissions = Permissions.values;




 const menus = () => {
  const currentUser = getStore().getState().auth.currentUser;
 const currentTenant= getStore().getState().auth.currentTenant;
 const premissionChecker = new PermissionChecker(currentTenant, currentUser);

 const isSleepWellPlanActive =  premissionChecker.isSleepWellPlanActive;
 const isFrequenciesPlanActive = premissionChecker.isFrequenciesPlanActive;

  const menuList = [
    {
      path: '/home',
      label: i18n('home.title'),
      permissionRequired: null,
      imageIcon: '/images/home-icon.svg',
      isEnabled: (isSleepWellPlanActive || isFrequenciesPlanActive) ? false: true
    },
    {
      path: '/favorites',
      label: i18n('favorites.title'),
      permissionRequired: null,
      // icon: faHeart,
      imageIcon: '/images/Heart-thin.svg',
      isDisabled:  false
    },
    // {
    //   path: '/vip-wellness-plan',
    //   label: 'VIP Wellness Plan',
    //   permissionRequired: null,
    //   icon: null,
    //   // imageIcon: '/images/wellness-plan-icon.png',
    //   imageIcon: '/images/calendar-sun.svg',
    // },
    {
      path: '/frequencies',
      label: i18n('sound.title'),
      permissionRequired: null,
      icon: faPlay,
      // imageIcon: '/images/frequencies.png',
      imageIcon: '/images/frequency.svg',
      isDisabled: false
    },
    {
      path: '/mindful-intentions',
      label: i18n('affirmation.title'),
      permissionRequired: null,
      icon: faEnvelope,
      // imageIcon: '/images/intentions.png',
      imageIcon: '/images/wellness.svg',
      isDisabled: false
    },
    // disabled for now
    // {
    //   path: '/courses',
    //   label: i18n('Courses'),
    //   permissionRequired: null,
    //   icon: faCommentDots,
    //   // imageIcon: '/images/programs.png',
    //   imageIcon: '/images/course.svg',
    //   isDisabled: isSleepWellPlanActive || isFrequenciesPlanActive
    // },
    {
      path: '/21-day-focus',
      label: i18n('affirmationProgram.title'),
      permissionRequired: null,
      icon: faCommentDots,
      // imageIcon: '/images/programs.png',
      imageIcon: '/images/message.svg',
      isDisabled: isSleepWellPlanActive || isFrequenciesPlanActive
    },

    // {
    //   path: '/health-biometrics',
    //   label: 'Health Biometrics',
    //   permissionRequired: null,
    //   icon: null,
    //   // imageIcon: '/images/biometrics.png',
    //   imageIcon: '/images/statistics.svg',

    // },

    // {
    //   href: 'https://soaak.com/blog/',
    //   label: 'Blog',
    //   permissionsRequired: null,
    //   icon: faNewspaper,
    // },

    {
      path: '/settings',
      label: i18n('settings.title'),
      permissionRequired: null,
      // icon: faCog,
      imageIcon: '/images/settings.svg',
      isDisabled:false
    },

    // {
    //   // href: 'https://www.soaak.com/pricing',
    //   path: '/pricing',
    //   label: 'Membership Options',
    //   permissionsRequired: null,
    //   // icon: faAddressCard,
    //   imageIcon: '/images/membership.svg',
    // },

    // {
    //   href: 'https://soaak.com/faqs/',
    //   label: 'FAQ',
    //   permissionsRequired: null,
    //   icon: faQuestionCircle,
    // },
    // {
    //   href: 'https://apps.apple.com/us/app/soaak/id1574035846',
    //   label: 'Download our App',
    //   permissionsRequired: null,
    //   // icon: faApple,
    //   imageIcon: '/images/downloadMenuImage.svg',
    // },
    {
      href: '',
      label: 'Download Mobile App',
      permissionsRequired: null,
      // icon: faApple,
      imageIcon: '/images/downloadMenuImage.svg',
      isDisabled: false
    },
    // {
    //   href: 'https://play.google.com/store/apps/details?id=com.soaak',
    //   label: 'Download Android App',
    //   permissionsRequired: null,
    //   // icon: faAndroid,
    //   imageIcon: '/images/android.svg',
    // },
    {
      href: 'https://skills-store.amazon.com/deeplink/dp/B09CD2V7Y1?deviceType=app&share',
      label: 'Enable Alexa Skill',
      permissionsRequired: null,
      // icon: faAmazon,
      imageIcon: '/images/alexa.svg',
      isDisabled: isSleepWellPlanActive || isFrequenciesPlanActive
    },
  ].filter(Boolean);
  return menuList;
}

export default menus; 